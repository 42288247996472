var bookings = {
  templateUrl: './bookings.html',
  controller: 'BookingsController'
};

angular
  .module('components.admin')
  .component('bookings', bookings)
  .config(function($stateProvider) {
    $stateProvider
      .state('admin.bookings', {
        url: '/bookings',
        component: 'bookings'
      })
    ;
  })
;
