function RegisterController(AuthService) {
  var ctrl = this;

  ctrl.step = 1;

  ctrl.$onInit = function () {
    ctrl.error = null;
    ctrl.user = {
      email: '',
      password: ''
    };
  };

  ctrl.step1Submit = function() {
    ctrl.step = 2;
  };

  ctrl.step2Submit = function() {
    AuthService.register(ctrl.user)
      .then(function(response) {
        return AuthService.saveUserToDatabase(response.uid, ctrl.user)
      }, registerError)
      .then(function() {
        ctrl.step = 3;
        AuthService.logout();
      }, registerError)
    ;
  };

  function registerError(error) {
    ctrl.step = 1;
    ctrl.error = error.message;
    return Promise.reject(error);
  }
}

angular
  .module('components.auth')
  .controller('RegisterController', RegisterController);
