function BackgroundsController($firebaseArray) {
  var ctrl = this;
  var ref = firebase.database().ref('bgImgs');
  ctrl.bgImgs = $firebaseArray(ref);
  ctrl.seconds = 7;
}

angular
  .module('components.auth')
  .controller('BackgroundsController', BackgroundsController);
