function LoginController(AuthService, $state, moment) {
  var ctrl = this;
  //var auth = $firebaseAuth();

  ctrl.$onInit = function () {
    ctrl.error = null;
    ctrl.user = {
      email: '',
      password: ''
    };
  };
  ctrl.loginUser = function (event) {
    return AuthService
      .login(event.user)
      .then(function (user) {
        ctrl.user = user;
        if (user.emailVerified === false) {
          user.sendEmailVerification()
            .then(function() {
              ctrl.error = 'We need to verify your email address. Please click on the link in the email we\'ve just sent to you.';
              AuthService.logout();
            })
          ;
        }
        else {
          var ref = firebase.database().ref('users');
          ref.child(user.uid).once('value')
            .then(function(snapshot) {
              var dbUser = snapshot.val();
              var emailCheck = dbUser.emailCheck;
              if (moment(emailCheck).isBefore(moment().subtract(6, 'months'))) {
                ref.child(ctrl.user.uid)
                  .update({
                    emailVerified: false,
                    emailCheck: moment().format()
                  })
                  .then(function() {
                    var user = AuthService.getUser();
                    user.sendEmailVerification()
                      .then(function() {
                        ctrl.error = 'We need to verify your email address. Please click on the link in the email we\'ve just sent to you.';
                        AuthService.logout();
                      })
                    ;
                  })
                ;
              }
              else {
                ref.child(ctrl.user.uid)
                  .update({
                    emailVerified: true
                  })
                  .then(function() {
                    $state.go('app');
                  })
                ;
              }
            })
          ;
        }
      }, function () {
        ctrl.error = 'Email address or password is incorrect.';
      })
    ;
  };
}

angular
  .module('components.auth')
  .controller('LoginController', LoginController);
