var filters = {
  templateUrl: './filters.html',
  controller: 'FiltersController',
  bindings: {
    onUpdate: '&'
  }
};

angular
  .module('components.livesite')
  .component('filters', filters)
;
