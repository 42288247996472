var admin = {
  templateUrl: './admin.html'
};

angular
  .module('components.admin')
  .component('admin', admin)
  .config(function($stateProvider) {
    $stateProvider
      .state('admin', {
        parent: 'app',
        redirectTo: 'admin.users',
        url: '/admin',
        component: 'admin'
      })
    ;
  })
;
