angular
  .module('common', [
    'ui.router',
    'ngAria',
    'ngAnimate',
    'ngMaterial',
    'md.data.table',
    'angular.filter',
    'ngSanitize',
    'angularMoment',
    'angularModalService'
  ])
  .config(function($mdThemingProvider, $mdDateLocaleProvider, moment) {
    $mdThemingProvider.theme('default')
      .primaryPalette('blue-grey')
      .accentPalette('pink')
      .warnPalette('deep-orange')
      .backgroundPalette('grey')
    ;
    $mdDateLocaleProvider.formatDate = function(date) {
      return moment(date).format('DD/MM/YYYY');
    };
  })
  .run(function ($transitions, $rootScope) {
    $transitions.onStart({}, function() {
      $rootScope.showContactMenu = false;
      $rootScope.showUserMenu = false;
    });
    // $transitions.onSuccess({}, function() {
    //   // onSuccess stuff
    // });
  })
;
