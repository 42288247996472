var forgotPassword = {
  templateUrl: './forgot-password.html',
  controller: 'ForgotPasswordController'
};

angular
  .module('components.auth')
  .component('forgotPassword', forgotPassword)
  .config(function($stateProvider) {
    $stateProvider
      .state('auth.forgotPassword', {
        url: '/forgot-password',
        component: 'forgotPassword'
      })
    ;
  })
;
