function FiltersController($timeout) {
  var ctrl = this;

  ctrl.refTypesDisabled = false;
  ctrl.devicesDisabled = false;

  ctrl.inSectionsDisabled = false;
  ctrl.esSectionsDisabled = false;
  ctrl.i1SectionsDisabled = false;

  ctrl.filters = {};
  ctrl.filters.site = ['in','es','i1','hp'];
  ctrl.filters.section = [
    'in_news',
    'in_business',
    'in_sport',
    'in_arts-entertainment',
    'in_life-style',
    'in_gadgets-and-tech',
    'in_travel',
    'es_news',
    'es_business',
    'es_sport',
    'es_goingout',
    'es_lifestyle',
    'es_showbiz',
    'es_travel',
    'i1_news',
    'i1_sport',
    'i1_tech',
    'i1_ents'
  ];
  ctrl.filters.device = [
    'd',
    'm'
  ];
  ctrl.filters.refType = [
    'search',
    'social',
    'links',
    'internal'
  ];

  ctrl.sites = [
    {
      name: 'in',
      fullName: 'The Independent'
    },
    {
      name: 'es',
      fullName: 'Evening Standard'
    },
    {
      name: 'i1',
      fullName: 'indy100'
    },
    {
      name: 'hp',
      fullName: 'Homes & Property'
    }
  ];

  ctrl.sections = [
    // Indy
    {
      name: 'in_news',
      displayName: 'News',
      site: 'in'
    },
    {
      name: 'in_business',
      displayName: 'Business',
      site: 'in'
    },
    {
      name: 'in_sport',
      displayName: 'Sport',
      site: 'in'
    },
    {
      name: 'in_arts-entertainment',
      displayName: 'Culture',
      site: 'in'
    },
    {
      name: 'in_life-style',
      displayName: 'Lifestyle',
      site: 'in'
    },
    {
      name: 'in_gadgets-and-tech',
      displayName: 'Tech',
      site: 'in'
    },
    {
      name: 'in_travel',
      displayName: 'Travel',
      site: 'in'
    },
    // ES
    {
      name: 'es_news',
      displayName: 'News',
      site: 'es'
    },
    {
      name: 'es_business',
      displayName: 'Business',
      site: 'es'
    },
    {
      name: 'es_sport',
      displayName: 'Sport',
      site: 'es'
    },
    {
      name: 'es_goingout',
      displayName: 'Going Out',
      site: 'es'
    },
    {
      name: 'es_lifestyle',
      displayName: 'Lifestyle',
      site: 'es'
    },
    {
      name: 'es_showbiz',
      displayName: 'Showbiz',
      site: 'es'
    },
    {
      name: 'es_travel',
      displayName: 'Travel',
      site: 'es'
    },
    // indy100
    {
      name: 'i1_news',
      displayName: 'News',
      site: 'i1'
    },
    {
      name: 'i1_sport',
      displayName: 'Sport',
      site: 'i1'
    },
    {
      name: 'i1_tech',
      displayName: 'Tech',
      site: 'i1'
    },
    {
      name: 'i1_ents',
      displayName: 'Ents',
      site: 'i1'
    }
  ];

  ctrl.devices = [
    {
      name: 'd',
      fullName: 'Desktop'
    },
    {
      name: 'm',
      fullName: 'Mobile'
    }
  ];

  ctrl.refTypes = [
    {
      name: 'search',
      fullName: 'Search'
    },
    {
      name: 'social',
      fullName: 'Social'
    },
    {
      name: 'links',
      fullName: 'Links/Bookmarked'
    },
    {
      name: 'internal',
      fullName: 'Internal'
    }
  ];

  ctrl.selectedSites = ['in','es','i1','hp'];
  ctrl.toggle = function(item, list) {
    var idx = list.indexOf(item);
    if (idx > -1) {
      list.splice(idx, 1);
      ctrl.filters.site = list;
    } else {
      list.push(item);
      ctrl.filters.site = list;
    }

    if (ctrl.filters.site.indexOf('in') > -1) {
      ctrl.inSectionsDisabled = false;
    }
    else {
      ctrl.inSectionsDisabled = true;
    }
    if (ctrl.filters.site.indexOf('es') > -1) {
      ctrl.esSectionsDisabled = false;
    }
    else {
      ctrl.esSectionsDisabled = true;
    }
    if (ctrl.filters.site.indexOf('i1') > -1) {
      ctrl.i1SectionsDisabled = false;
    }
    else {
      ctrl.i1SectionsDisabled = true;
    }

    ctrl.filtersChange();
  };
  ctrl.exists = function (item, list) {
    return list.indexOf(item) > -1;
  };

  ctrl.selectedSections = [
    'in_news',
    'in_business',
    'in_sport',
    'in_arts-entertainment',
    'in_life-style',
    'in_gadgets-and-tech',
    'in_travel',
    'es_news',
    'es_business',
    'es_sport',
    'es_goingout',
    'es_lifestyle',
    'es_showbiz',
    'es_travel',
    'i1_news',
    'i1_sport',
    'i1_tech',
    'i1_ents'
  ];
  ctrl.toggleSection = function(item, list) {
    var idx = list.indexOf(item);
    if (idx > -1) {
      list.splice(idx, 1);
      ctrl.filters.section = list;
    } else {
      list.push(item);
      ctrl.filters.section = list;
    }




    ctrl.filtersChange();
  };
  ctrl.existsSection = function (item, list) {
    return list.indexOf(item) > -1;
  };

  ctrl.selectedDevices = ['d', 'm'];
  ctrl.toggleDevice = function(item, list) {
    var idx = list.indexOf(item);
    if (idx > -1) {
      list.splice(idx, 1);
      ctrl.filters.device = list;
    } else {
      list.push(item);
      ctrl.filters.device = list;
    }
    if (ctrl.filters.device.length < 2) {
      ctrl.refTypesDisabled = true;
    }
    else {
      ctrl.refTypesDisabled = false;
    }
    ctrl.filtersChange();
  };
  ctrl.existsDevice = function (item, list) {
    return list.indexOf(item) > -1;
  };

  ctrl.selectedRefTypes = [
    'search',
    'social',
    'links',
    'internal'
  ];
  ctrl.toggleRefType = function(item, list) {
    var idx = list.indexOf(item);
    if (idx > -1) {
      list.splice(idx, 1);
      ctrl.filters.refType = list;
    } else {
      list.push(item);
      ctrl.filters.refType = list;
    }
    if (ctrl.filters.refType.length < 4) {
      ctrl.devicesDisabled = true;
    }
    else {
      ctrl.devicesDisabled = false;
    }
    ctrl.filtersChange();
  };
  ctrl.existsRefType = function (item, list) {
    return list.indexOf(item) > -1;
  };

  var _timeout;
  ctrl.filtersChange = function() {
    if (_timeout) {
      $timeout.cancel(_timeout);
    }
    _timeout = $timeout(function() {
      ctrl.onUpdate({
        $event: {
          filters: ctrl.filters
        }
      });
    }, 500);
  };
}

angular
  .module('components.livesite')
  .controller('FiltersController', FiltersController);
