var users = {
  templateUrl: './users.html',
  controller: 'UsersController'
};

angular
  .module('components.admin')
  .component('users', users)
  .config(function($stateProvider) {
    $stateProvider
      .state('admin.users', {
        url: '/users',
        component: 'users'
      })
    ;
  })
;
