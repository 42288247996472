 function AuthService($firebaseAuth) {
  var auth = $firebaseAuth();
  var authData = null;
  var ref = firebase.database().ref();

  function storeAuthData(response) {
    authData = response;
    return authData;
  }

  function onSignIn(user) {
    authData = user;
    return auth.$requireSignIn();
  }

  function clearAuthData() {
    authData = null;
  }
  this.login = function (user) {
    return auth
      .$signInWithEmailAndPassword(user.email, user.password)
      .then(storeAuthData);
  };

  this.register = function (user) {
    return auth
      .$createUserWithEmailAndPassword(user.email, user.password)
      .then(storeAuthData);
  };

  this.saveUserToDatabase = function(uid, userData) {
    return ref.child('users').child(uid).set({
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      jobTitle: userData.jobTitle,
      agency: userData.agency,
      receiveUpdates: userData.receiveUpdates || false,
      phone: userData.phone || null,
      disabled: true,
      accountPending: true,
      emailVerified: true // We don't require user to verify this on registration.
    });
  }

  this.logout = function () {
    return auth
      .$signOut()
      .then(clearAuthData);
  };

  this.requireAuthentication = function () {
    return auth
      .$waitForSignIn().then(onSignIn);
  };

  this.isAuthenticated = function () {
    return !!authData;
  };

  this.getUser = function () {
    if (authData) {
      return authData;
    }
  };
}

angular
  .module('components.auth')
  .service('AuthService', AuthService)
;
