var livesite = {
  templateUrl: './livesite.html',
  controller: 'LivesiteController'
};

angular
  .module('components.livesite')
  .component('livesite', livesite)
  .config(function($stateProvider) {
    $stateProvider
      .state('livesite', {
        parent: 'app',
        url: '/livesite',
        component: 'livesite'
      })
    ;
  })
;
