var bgImgs = {
  templateUrl: './bg-imgs.html',
  controller: 'BgImgsController'
};

angular
  .module('components.admin')
  .component('bgImgs', bgImgs)
  .config(function($stateProvider) {
    $stateProvider
      .state('admin.bgImgs', {
        url: '/bg-imgs',
        component: 'bgImgs'
      })
    ;
  })
;
