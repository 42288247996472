function ForgotPasswordController($firebaseAuth) {
  var ctrl = this;
  var auth = $firebaseAuth();
  ctrl.step = 1;
  ctrl.submit = function() {
    auth.$sendPasswordResetEmail(ctrl.email)
      .then(function() {
        ctrl.step = 2;
      })
      .catch(function(result) {
        if (result.code === 'auth/user-not-found') {
          ctrl.error = 'Sorry, no account was found.';
        }
        else {
          ctrl.error = 'Sorry, there was a problem sending the password reset email.';
        }
      })
    ;
  };
}

angular
  .module('components.auth')
  .controller('ForgotPasswordController', ForgotPasswordController);
