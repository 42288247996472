function BookingsController($firebaseArray) {
  var ctrl = this;
  var ref = firebase.database().ref('bookings');
  ctrl.bookings = $firebaseArray(ref);

  ctrl.deleteBooking = function (id) {
    ref.child(id).remove();
  };

  ctrl.itemsPerPage = 10;
  ctrl.currentPage = 0;
  ctrl.prevPage = function() {
    if (ctrl.currentPage > 0) {
      ctrl.currentPage--;
    }
  };
  ctrl.prevPageDisabled = function() {
    return ctrl.currentPage === 0 ? "disabled" : "";
  };
  ctrl.pageCount = function() {
    return Math.ceil(ctrl.bookings.length/ctrl.itemsPerPage)-1;
  };
  ctrl.nextPage = function() {
    if (ctrl.currentPage < ctrl.pageCount()) {
      ctrl.currentPage++;
    }
  };
  ctrl.nextPageDisabled = function() {
    return ctrl.currentPage === ctrl.pageCount() ? "disabled" : "";
  };

  ctrl.search = function(row) {
    return (angular.lowercase(row.displayName).indexOf(angular.lowercase(ctrl.searchQuery) || '') !== -1 ||
      angular.lowercase(row.email).indexOf(angular.lowercase(ctrl.searchQuery) || '') !== -1);
  };
}

function Sites() {
  return function(value) {
    var sites = '';
    for (var i = 0; i < value.length; i++) {
      if (i !== 0) {
        sites += ', ';
      }
      if (value[i] === 'in') {
        sites += 'Independent';
      }
      else if (value[i] === 'es') {
        sites += 'ES';
      }
      else if (value[i] === 'i1') {
        sites += 'indy100';
      }
      else if (value[i] === 'hp') {
        sites += 'H&P';
      }
    }
    return sites;
  };
}

function Sections() {
  return function(value) {
    var sections = '';
    for (var i = 0; i < value.length; i++) {
      value[i] = value[i].replace('in_', '');
      value[i] = value[i].replace('es_', '');
      value[i] = value[i].replace('i1_', '');
      value[i] = value[i].replace('-', ' ');
      value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
      if (sections.indexOf(value[i]) === -1) {
        if (i !== 0) {
          sections += ', ';
        }
        sections += value[i];
      }
    }
    return sections;
  };
}

function Devices() {
  return function(value) {
    var devices = '';
    for (var i = 0; i < value.length; i++) {
      if (i !== 0) {
        devices += ', ';
      }
      if (value[i] === 'm') {
        devices += 'Mobile';
      }
      else if (value[i] === 'd') {
        devices += 'Desktop';
      }
    }
    return devices;
  };
}

function RefTypes() {
  return function(value) {
    var refTypes = '';
    for (var i = 0; i < value.length; i++) {
      if (i !== 0) {
        refTypes += ', ';
      }
      refTypes += value[i].charAt(0).toUpperCase() + value[i].slice(1);
    }
    return refTypes;
  };
}

function offset() {
  return function(input, start) {
    start = parseInt(start, 10);
    return input.slice(start);
  };
}

angular
  .module('components.admin')
  .controller('BookingsController', BookingsController)
  .filter('Sites', Sites)
  .filter('Sections', Sections)
  .filter('Devices', Devices)
  .filter('RefTypes', RefTypes)
  .filter('offset', offset)
;
