function ChartbeatService($http, $q, AuthService) {
  var model = this,
      URL = 'https://us-central1-esi-realtime.cloudfunctions.net/app/chartbeat-data/?source=esirealtime';

  function extractResult(result) {
    return result.data;
  }

  function errorHandle(response) {
    console.log('error...', response);
    return $q.reject(response.data);
  }

  var canceler = $q.defer();
  var resolved = false;

  var cancel = function() {
    canceler.resolve('http call aborted');
  };

  model.getData = function(sites, sections, devices, refTypes) {
    if (resolved) {
      cancel();
    }

    canceler = $q.defer();
    resolved = true;

    var queryString = '';
    angular.forEach(sites, function(site, key) {
      queryString += '&sites[]=' + site;
    });
    angular.forEach(sections, function(section, key) {
      if (section.substring(0, 3) == 'in_') {
        queryString += '&sections_in[]=' + section.substring(3);
      }
      else if (section.substring(0, 3) == 'es_') {
        queryString += '&sections_es[]=' + section.substring(3);
      }
      else if (section.substring(0, 3) == 'i1_') {
        queryString += '&sections_i1[]=' + section.substring(3);
      }
    });
    if (!(devices.indexOf('d') > -1 && devices.indexOf('m') > -1)) {
      for (var i = 0; i < devices.length; i++) {
        queryString += '&devices[]=' + devices[i];
      }
    }
    if (!(refTypes.indexOf('search') > -1 && refTypes.indexOf('social') > -1
      && refTypes.indexOf('links') > -1 && refTypes.indexOf('internal') > -1)) {
      for (var j = 0; j < refTypes.length; j++) {
        queryString += '&refTypes[]=' + refTypes[j];
      }
    }

    var user = AuthService.getUser();
    var token = user.kd;
    var req = {
     method: 'GET',
     url: URL + queryString,
     headers: {
       'Authorization': 'Bearer ' + token
     }
    }
    return $http(req, {timeout: canceler.promise}).then(extractResult, errorHandle);
  };
}

angular
  .module('components.livesite')
  .service('ChartbeatService', ChartbeatService)
;
