function BgImgsController($firebaseArray, $firebaseObject, $firebaseStorage) {
  var ctrl = this;
  var ref = firebase.database().ref('bgImgs');
  ctrl.bgImgs = $firebaseArray(ref);

  var storage = firebase.storage();
  var storageRef = storage.ref();

  ctrl.uploadFile = function() {
    var timestamp = Date.now();
    var fileRef = storageRef.child('bgImgs/' + timestamp);
    var uploadTask = $firebaseStorage(fileRef).$put(ctrl.file);
    uploadTask.$progress(function(snapshot) {
      ctrl.uploading = true;
      ctrl.percentUploaded = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    });
    uploadTask.$complete(function(result) {
      ctrl.bgImgs.$add({
        filePath: result.metadata.fullPath,
        url: result.downloadURL
      }).then(function() {
        ctrl.file = null;
        ctrl.uploading = false;
      });
    });
  };

  ctrl.deleteImg = function(bgImg) {
    var fileRef = storageRef.child(bgImg.filePath);
    fileRef.delete()
      .then(function() {
        var item = ref.child(bgImg.$id);
        var obj = $firebaseObject(item);
        obj.$remove();
      })
    ;
  };
}

function fileModel($parse) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      element.bind('change', function () {
        $parse(attrs.fileModel)
        .assign(scope, element[0].files[0])
        scope.$apply();
      })
    }
  }
}

angular
  .module('components.admin')
  .controller('BgImgsController', BgImgsController)
  .directive('fileModel', fileModel)
;
