var insightView = {
  templateUrl: './insight-view.html',
  controller: 'InsightViewController',
  bindings: {
    chartbeatData: '<',
    sites: '<',
    sections: '<',
    loading: '<',
    filters: '<'
  }
};

angular
  .module('components.livesite')
  .component('insightView', insightView)
;
