var messages = {
  templateUrl: './messages.html',
  controller: 'MessagesController'
};

angular
  .module('components.admin')
  .component('messages', messages)
  .config(function($stateProvider) {
    $stateProvider
      .state('admin.messages', {
        url: '/messages',
        component: 'messages'
      })
    ;
  })
;
