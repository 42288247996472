var action = {
  templateUrl: './action.html',
  controller: 'ActionController'
};

angular
  .module('components.auth')
  .component('action', action)
  .config(function ($stateProvider) {
    $stateProvider
      .state('auth.action', {
        url: '/action?mode&oobCode',
        component: 'action'
      })
    ;
  })
;
