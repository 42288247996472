function AppNavController($rootScope, ModalService, AuthService) {
  var ctrl = this;
  var user = AuthService.getUser();

  var admins = [
    'rebecca.fickling@independent.co.uk',
    'jo.holdaway@esimedia.co.uk',
    'matthew.wrightson@independent.co.uk',
    'mitesh.sevani@independent.co.uk'
  ];

  var email = user.email.toLowerCase();
  if (admins.indexOf(email) > -1
    || email.indexOf('@brightsites.co.uk')  > -1) {
    ctrl.adminUser = true; // There is server side authentication as well!
  }

  ctrl.toggleContactMenu = function() {
    $rootScope.showUserMenu = false;
    $rootScope.showContactMenu = !$rootScope.showContactMenu;
  }

  ctrl.toggleUserMenu = function() {
    $rootScope.showContactMenu = false;
    $rootScope.showUserMenu = !$rootScope.showUserMenu;
  }

  ctrl.showContactModal = function() {
    ModalService.showModal({
      templateUrl: './contact-modal.html',
      controller: 'ShowContactController',
      controllerAs: '$ctrl'
    });
  };

  $rootScope.showBookingModal = function() {
    ModalService.showModal({
      templateUrl: './booking-modal.html',
      controller: 'ShowBookingController',
      controllerAs: '$ctrl'
    });
  };

  ctrl.showAccountModal = function() {
    ModalService.showModal({
      templateUrl: './account-modal.html',
      controller: 'ShowAccountController',
      controllerAs: '$ctrl'
    });
  };
}

function ShowContactController(close, $rootScope, AuthService) {
  var ctrl = this;
  var ref = firebase.database().ref('messages');
  $rootScope.showContactMenu = false;
  ctrl.step = 1;
  ctrl.submit = function() {
    ctrl.submitting = true;
    var user = AuthService.getUser();
    ref.push()
      .set({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        message: ctrl.messageContent
      })
      .then(function() {
        ctrl.submitting = false;
        ctrl.step = 2;
      })
      .catch(function() {
        ctrl.submitting = false;
        ctrl.message = 'Sorry there was an error sending your request.'
      })
    ;
  }
  ctrl.close = function() {
    close();
  };
}

function ShowBookingController(close, $rootScope, AuthService) {
  var ctrl = this;
  var ref = firebase.database().ref('bookings');
  $rootScope.showContactMenu = false;
  ctrl.step = 1;
  ctrl.regions = ['UK', 'USA'];
  ctrl.adSizes = ['MPU', 'DMPU', 'Leaderboard', 'Billboard'];
  ctrl.devices = [
    {
      key: 'd',
      value: 'Desktop'
    },
    {
      key: 'm',
      value: 'Mobile'
    }
  ];
  ctrl.refTypes = [
    {
      key: 'search',
      value: 'Search'
    },
    {
      key: 'social',
      value: 'Social'
    },
    {
      key: 'links',
      value: 'Links/Bookmarked'
    },
    {
      key: 'internal',
      value: 'Internal'
    }
  ];

  ctrl.submit = function() {
    ctrl.submitting = true;
    var data = ctrl.form;
    var user = AuthService.getUser();
    var filters = $rootScope.filters;
    ref.push()
      .set({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        bookingStart: data.bookingStart.toString() || null,
        bookingEnd: data.bookingEnd.toString() || null,
        sites: filters.site || null,
        sections: filters.section || null,
        devices: data.device || null,
        refTypes: data.refType || null,
        region: data.region || null,
        adSize: data.adSize || null,
        audience: data.audience || null,
        estimatedSpend: data.estimatedSpend || null,
        impressionsVolume: data.impressionsVolume || null,
        specialRequests: data.specialRequests || null
      })
      .then(function() {
        ctrl.submitting = false;
        ctrl.step = 2;
      })
      .catch(function() {
        ctrl.submitting = false;
        ctrl.message = 'Sorry there was an error sending your request.'
      })
    ;
  }
  ctrl.close = function() {
    close();
  };
}

function ShowAccountController(close, $rootScope, AuthService) {
  var ctrl = this;
  var ref = firebase.database().ref('users');
  $rootScope.showUserMenu = false;
  ctrl.step = 1;
  var user = AuthService.getUser();
  ref.child(user.uid).once('value').then(function(snapshot) {
    ctrl.user = snapshot.val();
    ctrl.userDataLoaded = true;
  });

  ctrl.submit = function() {
    ctrl.submitting = true;
    ref.child(user.uid).update({
        firstName: ctrl.user.firstName,
        lastName: ctrl.user.lastName,
        jobTitle: ctrl.user.jobTitle,
        agency: ctrl.user.agency,
        phone: ctrl.user.phone
      })
      .then(function() {
        ctrl.submitting = false;
        ctrl.step = 2;
      })
      .catch(function() {
        ctrl.submitting = false;
        ctrl.message = 'Sorry there was an error updating your account.'
      })
    ;
  }
  ctrl.close = function() {
    close();
  };
}

angular
  .module('common')
  .controller('AppNavController', AppNavController)
  .controller('ShowContactController', ShowContactController)
  .controller('ShowBookingController', ShowBookingController)
  .controller('ShowAccountController', ShowAccountController)
;
