function UsersController($firebaseArray) {
  var ctrl = this;
  var ref = firebase.database().ref('users');
  ctrl.users = $firebaseArray(ref);

  ctrl.itemsPerPage = 10;
  ctrl.currentPage = 0;
  ctrl.prevPage = function() {
    if (ctrl.currentPage > 0) {
      ctrl.currentPage--;
    }
  };
  ctrl.prevPageDisabled = function() {
    return ctrl.currentPage === 0 ? "disabled" : "";
  };
  ctrl.pageCount = function() {
    return Math.ceil(ctrl.users.length/ctrl.itemsPerPage)-1;
  };
  ctrl.nextPage = function() {
    if (ctrl.currentPage < ctrl.pageCount()) {
      ctrl.currentPage++;
    }
  };
  ctrl.nextPageDisabled = function() {
    return ctrl.currentPage === ctrl.pageCount() ? "disabled" : "";
  };

  ctrl.enableUser = function (uid) {
    ref.child(uid).update({
      disabled: false
    });
  };

  ctrl.disableUser = function (uid) {
    ref.child(uid).update({
      disabled: true
    });
  };

  ctrl.rejectUser = function (uid) {
    ref.child(uid).update({
      accountPending: false
    });
  };

  ctrl.deleteUser = function (uid) {
    if(confirm('Are you sure you want to delete the user?')){
      ref.child(uid).remove().then(function() {
        console.log("Remove succeeded.")
      })
      .catch(function(error) {
        console.log("Remove failed: " + error.message)
      });
    }
  };

  ctrl.search = function(row) {
    return (angular.$$lowercase(row.firstName).indexOf(angular.$$lowercase(ctrl.searchQuery) || '') !== -1 ||
      angular.$$lowercase(row.lastName).indexOf(angular.$$lowercase(ctrl.searchQuery) || '') !== -1 ||
      angular.$$lowercase(row.email).indexOf(angular.$$lowercase(ctrl.searchQuery) || '') !== -1);
  };
}

function Status() {
  return function(value) {
    if (value === true) {
      return 'Disabled';
    }
    else {
      return 'Enabled';
    }
  };
}

function YesNo() {
  return function(value) {
    if (value === true) {
      return 'Yes';
    }
    else if (value === false) {
      return 'No';
    }
  };
}


angular
  .module('components.admin')
  .controller('UsersController', UsersController)
  .filter('Status', Status)
  .filter('YesNo', YesNo)
;
