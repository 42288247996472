var root = {
  templateUrl: './root.html'
};

angular
  .module('root')
  .component('root', root)
  .config(function($locationProvider, $windowProvider) {
    if ($windowProvider.$get().location.origin !== 'http://localhost:4000') {
      $locationProvider.html5Mode(true);
    }
  })
;
