function LivesiteController(ChartbeatService, $timeout, $scope, $rootScope) {
  var ctrl = this;

  ctrl.loading = true;

  // Cancel timer if we already have it.
  $timeout.cancel(ctrl.timer);

  ctrl.sites = ['in','es','i1','hp'];
  ctrl.sections = [
    'in_news',
    'in_business',
    'in_sport',
    'in_arts-entertainment',
    'in_life-style',
    'in_gadgets-and-tech',
    'in_travel',
    'es_news',
    'es_business',
    'es_sport',
    'es_goingout',
    'es_lifestyle',
    'es_showbiz',
    'es_travel',
    'i1_news',
    'i1_sport',
    'i1_tech',
    'i1_ents'
  ];
  ctrl.devices = [
    'm',
    'd'
  ];
  ctrl.refTypes = [
    'search',
    'social',
    'links',
    'internal'
  ];

  // Assign to rootScope for use in the booking form.
  $rootScope.filters = {};
  $rootScope.filters.site = ctrl.sites;
  $rootScope.filters.section = ctrl.sections;
  $rootScope.filters.device = ctrl.devices;
  $rootScope.filters.refType = ctrl.refTypes;

  getData();


  ctrl.updateFilterValues = function(event) {
    ctrl.loading = true;

    // Cancel timer if we already have it.
    $timeout.cancel(ctrl.timer);

    // Assign to rootScope for use in the booking form.
    $rootScope.filters = event.filters;

    ctrl.sites = event.filters.site || [];
    ctrl.sections = event.filters.section || [];
    ctrl.devices = event.filters.device || [];
    ctrl.refTypes = event.filters.refType || [];

    getData();
  };

  function getData() {
    ChartbeatService.getData(ctrl.sites, ctrl.sections, ctrl.devices, ctrl.refTypes)
      .then(function(result) {
        ctrl.chartbeatData = result;
        ctrl.loading = false;
      })
    ;
    ctrl.timer = $timeout(getData, 5000);
  }

  $scope.$on('$destroy', function(){
    $timeout.cancel(ctrl.timer);
  });
}

angular
  .module('components.livesite')
  .controller('LivesiteController', LivesiteController);
