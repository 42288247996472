angular
  .module('components.auth', [
    'ui.router',
    'firebase',
    'ngPassword'
  ])
  .config(function ($firebaseRefProvider) {

    var config = {
      apiKey: "AIzaSyBaxDctflFTbCj0CHyzKYiAWs3YscOrfjg",
      authDomain: "esi-realtime.firebaseapp.com",
      databaseURL: "https://esi-realtime.firebaseio.com",
      storageBucket: "esi-realtime.appspot.com",
      messagingSenderId: "162842076220"
    };

    $firebaseRefProvider
      .registerUrl({
        default: config.databaseURL,
        users: config.databaseURL + '/users'
      });

    firebase.initializeApp(config);
  })
  .run(function ($transitions, $state, AuthService) {
    $transitions.onStart({
      to: function (state) {
        return !!(state.data && state.data.requiredAuth);
      }
    }, function() {
      return AuthService
        .requireAuthentication()
        .catch(function () {
          return $state.target('auth.login');
        });
    });
    $transitions.onStart({
      to: 'auth.*'
    }, function () {
      if (AuthService.isAuthenticated()) {
        return $state.target('app');
      }
    });
  })
;
