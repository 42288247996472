var siteIcon = function($filter) {
  return function(input) {
    var icon;

    // switch (expression) {
    //   case expression:
    //
    //     break;
    //   default:
    //
    // }

    switch (input) {
      case 'The Independent':
        icon = '/img/independent-logo.svg';
        break;
      case 'London Evening Standard':
        icon = '/img/es-logo.svg';
        break;
      case 'indy100':
        icon = '/img/indy100-logo.svg';
        break;
      case 'Homes & Property':
        icon = '/img/hp-logo.svg';
        break;
    }
    return icon;
  };
};

angular
  .module('common')
  .filter('siteIcon', siteIcon)
;
