function MessagesController($firebaseArray) {
  var ctrl = this;
  var ref = firebase.database().ref('messages');
  ctrl.messages = $firebaseArray(ref);

  ctrl.deleteMsg = function (id) {
    ref.child(id).remove();
  };

  ctrl.itemsPerPage = 10;
  ctrl.currentPage = 0;
  ctrl.prevPage = function() {
    if (ctrl.currentPage > 0) {
      ctrl.currentPage--;
    }
  };
  ctrl.prevPageDisabled = function() {
    return ctrl.currentPage === 0 ? "disabled" : "";
  };
  ctrl.pageCount = function() {
    return Math.ceil(ctrl.messages.length/ctrl.itemsPerPage)-1;
  };
  ctrl.nextPage = function() {
    if (ctrl.currentPage < ctrl.pageCount()) {
      ctrl.currentPage++;
    }
  };
  ctrl.nextPageDisabled = function() {
    return ctrl.currentPage === ctrl.pageCount() ? 'disabled' : '';
  };

  ctrl.search = function(row) {
    return (angular.lowercase(row.displayName).indexOf(angular.lowercase(ctrl.searchQuery) || '') !== -1 ||
      angular.lowercase(row.email).indexOf(angular.lowercase(ctrl.searchQuery) || '') !== -1);
  };
}

angular
  .module('components.admin')
  .controller('MessagesController', MessagesController)
;
